export default {
  props: {
    scopeProps: {
      type: Object,
      default(){
        return {
          footerSearch: '',
        }
      },
    },
  },
  watch: {
    'scopeProps.footerSearch'() {
      this.getList();
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
  